<template>
  <div>
    <validation-observer ref="logObserver">
      <b-sidebar
        id="updateLog"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-form"
        @hidden="sidebarToggle"
      >
        <!-- user form start -->

        <h3 class="form-title">
          Edit Log
        </h3>
        <div class="line user-underline" />
        <!-- In  Time -->
        <b-form-group>
          <label for="In Time">In Time <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            name="In Time"
            rules="required"
          >
            <b-form-input
              id="inTime"
              v-model="inTime"
              :state="errors.length > 0 ? false : null"
              placeholder="In Time"
              name="in_time"
              type="time"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- out  Time -->
        <b-form-group
          label="Out Time"
          label-for="Out Time"
        >

          <b-form-input
            id="outTime"
            v-model="outTime"
            placeholder="Out Time"
            name="out_time"
            type="time"
          />
        </b-form-group>
        <b-form-group>
          <label for="In Date">In Date <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            name="In Date"
            rules="required"
          >
            <b-input-group class="input-group-merge">
              <flat-pickr
                v-model="in_date"
                class="form-control"
                :config="configDate"
                placeholder="In Date"
                name="in_date"
              />
              <b-input-group-append is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <label for="Out Date">Out Date</label>
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="out_date"
              class="form-control"
              :config="outConfig"
              placeholder="Out Date"
              name="out_date"
            />
            <b-input-group-append is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!-- form footer -->
        <template #footer>
          <div class="form-button d-flex my-2">
            <button
              class="btn side-bar-btn"
              type="submit"
              :disabled="btnLoader || !out_date || !outTime"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              {{ id ? "Update" : "Save" }}
            </button>
            <button
              class="btn side-bar-btn"
              @click="clearData()"
            >
              Clear
            </button>
          </div>
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import * as moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { eventBus } from '@/main'

export default {
  name: 'ClientTableForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  data() {
    return {
      id: null,
      btnLoader: false,
      outTime: null,
      inTime: null,
      out_date: null,
      in_date: null,
      configDate: {
        maxDate: new Date(),
        dateFormat: 'Y/m/d',
      },
    }
  },
  computed: {

    outConfig() {
      const config = {
        minDate: this.in_date ? moment(this.in_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        maxDate: this.in_date ? moment(this.in_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        dateFormat: 'Y/m/d',
      }
      return config
    },
  },
  watch: {},

  mounted() {},
  methods: {
    /**
     * clear from detail
     */
    sidebarToggle() {
      this.id = null
      this.outTime = null
      this.inTime = null
      this.out_date = null
      this.in_date = null
      this.$refs.logObserver.reset()
    },
    /**
     * check the form validation
     * @returns if success true then call UpdateInOutLog Method
     */
    validationForm() {
      this.$refs.logObserver.validate().then(success => {
        if (success) {
          this.UpdateInOutLog()
        }
      })
    },
    /**
     * Add & Update the UserTableform details & call User listing in Usertable
     * @emits userList
     * @returns User detail add & update
     */
    async UpdateInOutLog() {
      this.btnLoader = true
      const input = {
        clock_id: this.id,
        in_time: moment(`${this.in_date} ${this.inTime}`).format('HH:mm:ss'),
        out_time: this.outTime && this.out_date ? moment(`${this.out_date} ${this.outTime}`).format('HH:mm:ss') : '',
        out_date: this.outTime && this.out_date ? moment(this.out_date).format('YYYY-MM-DD') : '',
        in_date: moment(this.in_date).format('YYYY-MM-DD'),
      }
      const response = await this.getHTTPPostResponse(
        'clock-in-out/update',
        input,
        true,
      )

      if (response && response.status === 200) {
        eventBus.$emit('LogUpdate', true)
        this.$root.$emit('bv::toggle::collapse', 'updateLog')
        setTimeout(() => {
          this.btnLoader = false
        }, 200)
      }
      this.btnLoader = false
    },
    /**
     * Clear form details
     */
    clearData() {
      this.inTime = null
      this.outTime = null
      this.outTime = null
      this.inTime = null
      this.out_date = null
      this.in_date = null

      this.$refs.logObserver.reset()
    },
  },
}
</script>

<style>
.primary-skill-select .vs__dropdown-toggle {
  padding: 10px !important;
}
.primary-skill-select .vs__dropdown-toggle .vs__selected {
  padding: 3px !important;
  margin-right: 2px !important;
  background-color: #2178fb;
  color: #ffff;
  font-weight: 600;
  margin-bottom: 2px !important;
}
.primary-skill-select .vs__dropdown-toggle .vs__selected .vs__deselect {
  fill: #ffff;
}
</style>
